<template>
  <el-dialog
    :title="$t('SelectGroup')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    class="select-group"
  >
    <!-- 分组区域 -->
    <ul class="group-list">
      <li
        v-for="item in allGroupList"
        :key="item.id"
        class="group-item"
      >
        <div class="left-box">
          <el-checkbox v-model="item.isChecked">{{''}}</el-checkbox>
        </div>
        <div class="right-box">
          <p class="group-name">{{ item.groupName }}</p>
          <p>{{ item.node }}</p>
        </div>
      </li>
    </ul>

    <span slot="footer">
      <el-button @click="cancelFn">{{ $t('Cancle') }}</el-button>
      <el-button
        class="confirm-btn"
        @click="submitData"
      >{{ $t('Confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { deepCopy } from "@/utils/tool.js";
import api from "@/fetch/api";

export default {
  name: "SelectGroup",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show;
      },
      set() {
        this.cancelFn();
      },
    },
  },
  data() {
    return {
      allGroupList: [], // 所有分组数据
    };
  },
  created() {
    this.getGroupMemberList();
  },
  methods: {
    // 获取分组成员列表
    getGroupMemberList() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        pageNum: 1,
        pageSize: 999,
        language: languageObj[locale],
        chamberId: data.chamberId,
      };
      api.getGroupByChamberId(params).then((res) => {
        const copyData = deepCopy(res.data.data.records);
        if (copyData.length) {
          copyData.forEach((item) => {
            item.isChecked = false;
          });
        }
        // 如果该组下没有会员，不让显示
        const filterData = copyData.filter((item) => item.groupNumber !== 0);
        this.allGroupList = filterData;

        const selectGroup = sessionStorage.getItem("selectGroup");
        if (selectGroup) this.handleDataSelect(selectGroup);
      });
    },

    // 处理数据选中
    handleDataSelect(data) {
      const selectGroup = JSON.parse(data);
      if (this.allGroupList.length) {
        this.allGroupList.forEach((item) => {
          selectGroup.forEach((sItem) => {
            if (item.id === sItem.id) item.isChecked = sItem.isChecked;
          });
        });
      }
    },

    // 取消
    cancelFn() {
      this.$emit("update:show", false);
    },

    // 确定
    submitData() {
      const filterSelect = this.allGroupList.filter((item) => item.isChecked);
      if (filterSelect.length) {
        sessionStorage.setItem("selectGroup", JSON.stringify(filterSelect));
        this.$parent.updateData(1);
        this.cancelFn();
      } else {
        this.$message.error({
          message: this.$t("SelectGroupTip"),
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.select-group {
  ul,
  li {
    list-style: none;
  }

  // 分组区域
  .group-list {
    max-height: 450px;
    overflow-y: auto;
    // 滚动条宽度为0（兼容火狐）
    scrollbar-width: none;
    // 滚动条宽度为0
    &::-webkit-scrollbar {
      display: none;
    }
    border: 1px solid #d6d6d6;
    .group-item {
      display: flex;
      align-items: center;
      padding: 10px;
      border-bottom: 1px solid #d6d6d6;
      & > .left-box {
        margin-right: 10px;
      }
      & > .right-box {
        flex: 1;
        width: 0;
        & > p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .group-name {
          margin-bottom: 10px;
        }
      }
    }
    .group-item:last-child {
      border-bottom: none;
    }
  }

  .confirm-btn {
    background-color: #589ef8;
    color: #ffffff;
  }
}
</style>
<style lang="scss">
.select-group {
  .el-dialog {
    min-width: 600px;
    width: 600px;
  }
  .el-dialog__header,
  .el-dialog__footer {
    text-align: center;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__footer {
    .el-button {
      margin: 0 10px;
    }
  }
}
</style>